@font-face {
  font-family: 'hammer';
  src: url('../font/hammer.eot?98928387');
  src: url('../font/hammer.eot?98928387#iefix') format('embedded-opentype'),
       url('../font/hammer.woff2?98928387') format('woff2'),
       url('../font/hammer.woff?98928387') format('woff'),
       url('../font/hammer.ttf?98928387') format('truetype'),
       url('../font/hammer.svg?98928387#hammer') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'hammer';
    src: url('../font/hammer.svg?98928387#hammer') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "hammer";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-left-open-big:before { content: '\3c'; } /* '&lt;' */
.icon-right-open-big:before { content: '\3e'; } /* '&gt;' */
.icon-mail:before { content: '\45'; } /* 'E' */
.icon-facebook:before { content: '\46'; } /* 'F' */
.icon-google:before { content: '\47'; } /* 'G' */
.icon-instagram:before { content: '\49'; } /* 'I' */
.icon-linkedin:before { content: '\4c'; } /* 'L' */
.icon-pinterest:before { content: '\50'; } /* 'P' */
.icon-share:before { content: '\53'; } /* 'S' */
.icon-twitter:before { content: '\54'; } /* 'T' */
.icon-cancel-popup:before { content: '\58'; } /* 'X' */
.icon-youtube:before { content: '\59'; } /* 'Y' */
.icon-search:before { content: '\73'; } /* 's' */
.icon-youtube-play:before { content: '\79'; } /* 'y' */
.icon-favorites:before { content: '\e800'; } /* '' */
.icon-products:before { content: '\e801'; } /* '' */
.icon-down:before { content: '\e802'; } /* '' */
.icon-expert:before { content: '\e803'; } /* '' */
.icon-favorites-full:before { content: '\e804'; } /* '' */
.icon-back:before { content: '\e805'; } /* '' */
.icon-quote:before { content: '\e806'; } /* '' */
.icon-user:before { content: '\e807'; } /* '' */
.icon-upload:before { content: '\e808'; } /* '' */
.icon-plus:before { content: '\e809'; } /* '' */
.icon-download:before { content: '\e80a'; } /* '' */
.icon-pdf:before { content: '\e80b'; } /* '' */
.icon-csv:before { content: '\e80c'; } /* '' */
.icon-delete:before { content: '\e80d'; } /* '' */
.icon-print:before { content: '\e80e'; } /* '' */
.icon-non:before { content: '\e80f'; } /* '' */
.icon-cancel-1:before { content: '\e810'; } /* '' */
.icon-logout:before { content: '\e811'; } /* '' */
.icon-filter:before { content: '\e812'; } /* '' */
.icon-right-open:before { content: '\e813'; } /* '' */
.icon-down-dir:before { content: '\e814'; } /* '' */
.icon-down-micro:before { content: '\e815'; } /* '' */
.icon-right-open-1:before { content: '\e816'; } /* '' */