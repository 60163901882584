.nav-item,
.nav-item:visited {
    text-decoration: none;
    border: none;
    color: #ffffff;
}

.disabled {
    pointer-events: none;
    color: #adadad !important;
}

nav {
    position: fixed;
    height: 62px;
    width: 100%;
    z-index: 2019;
}

nav ul li img {
    left: -2em;
    margin-top: 0.5em;
}

header {
    position: relative;
    top: 64px;
    z-index: 2018;
    text-align: center;
    width: 100%;
}

form {
    position: relative;
    z-index: 2018;
    top: 62px;
}

.row .col-xs-6 {
    margin-top: auto;
    padding-bottom: 10px;
}

.row .col-xs-6 div {
    margin-bottom: auto;
}
